<template>
  <div>
    <el-select
        v-model="selectVal"
        :placeholder="placeholder"
        clearable
        style="width: 100%"
    >
      <el-option
          v-for="(item, index) in dataList"
          :key="index"
          :label="item.sncode"
          :value="item.sncode"
      ></el-option>
    </el-select>
  </div>
</template>

<script>
import {getGangtingDeviceList} from "@/api/parking";

export default {
  name: "parking-passage",
  props: {
    value: String,
    placeholder: {
      type: String,
      default: "请选择-通道",
    },
    parkingId: String,
    // 通道类型 in | out
    deviceType: {
      type: String,
      default() {
        return "1";
      },
    },
  },
  data() {
    return {
      selectVal: "",
      dataList: [],
    };
  },
  watch: {
    parkingId(newVal) {
      if (newVal) {
        this.getPassageList();
      } else {
        this.dataList = [];
      }
    },
    value(newVal) {
      this.selectVal = !newVal || newVal === "" ? "" : newVal;
    },
    selectVal(newVal) {
      this.$emit("input", newVal);
    },
  },
  methods: {
    async getPassageList() {
      const res = await getGangtingDeviceList(this.parkingId);
      if (res && res.code === 30 && res.result && res.returnObject.length > 0) {
        const returnObject = res.returnObject;
        this.dataList = returnObject.filter((item) => {
          return item.deviceType === this.deviceType;
        });
      }
    },
  },
  created() {
    // this.getPassageList();
  },
};
</script>

<style lang="less" scoped>
</style>
