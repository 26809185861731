<template>
  <div>
    <el-select
        v-model="selectVal"
        :placeholder="placeholder"
        clearable
        style="width:100%"
    >
      <el-option
          v-for="(item, index) in dataList"
          :key="index"
          :label="item.userName"
          :value="item.id"
      ></el-option>
    </el-select>
  </div>
</template>

<script>
import {getSentryBoxList} from "@/api/common";

export default {
  name: "parking-person",
  props: {
    value: String,
    placeholder: {
      type: String,
      default: "请选择-收费人员"
    },
    parkingId: String,
  },
  data() {
    return {
      selectVal: "",
      dataList: [],
    };
  },
  watch: {
    parkingId(newVal) {
      if (newVal) {
        this.getPersonList();
      } else {
        this.dataList = [];
      }
    },
    value(newVal) {
      this.selectVal = !newVal || newVal === "" ? "" : newVal;
    },
    selectVal(newVal) {
      this.$emit("input", newVal);
    },
  },
  methods: {
    async getPersonList() {
      const res = await getSentryBoxList(this.parkingId);
      if (res && res.code === 30 && res.result && res.returnObject.list.length > 0) {
        const returnObject = res.returnObject;
        this.dataList = returnObject.list;
        // this.dataList = returnObject.filter((item) => {
        //   return item.deviceType === this.deviceType;
        // });
      }
    },
  },
  created() {
    // this.getPassageList();
  },
}
</script>
